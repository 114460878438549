export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getBase64 = (file: Blob) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader;
    reader.onload = (e) => {
        resolve(reader.result as string);
    };
    reader.readAsDataURL(file);
});

export const encodeRFC3986URIComponent = (str: string): string => {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
};

export const formDataEncodeAndAppendFile = (formData: FormData, fieldName: string, file: File, fileName?: string) => {
    const encodedFileName = encodeRFC3986URIComponent(fileName || file.name);
    formData.append(fieldName, file, encodedFileName);
};
