import { Dispatch } from 'redux';
import { saveAs } from 'file-saver';
import _ from 'lodash';

import { EOrderByType, EDirectionType, IAnswer, IAnswerDto, IAnswerDtoClausier, ICreateQueryClausier, ICreateQuestion, IDataTableData, IGetFaqForQnA, IGetMainQuestion, IQueryDtoClausier, IQuestionDto, ISearchFaqToQnA, ISingleQueryClausier, ISingleQuestion, EQuestionCategory, ICreateRightResponse, ICreateRight, IRightsMiniDatatableEntry, ETemplateType, IRelatedItem, IUpdateRight, ISearchFaq, ERelatedItemType } from '../entities/AssetManagement/actions';
import { ITemplateDataGridSettings } from '../entities/IClusters';
import { ELcid } from '../entities/ILanguage';
import { action } from '../tools/reduxTools';
import { IState } from '../reducers';
import { IContextReducer } from '../reducers/contextReducer';
import { IConfigReducer } from '../reducers/configReducer';
import { Http } from '../tools/http';
import { ETypes, ICondition, EAssetManagementBaseType, ExportType } from '../entities/AssetManagement/global';
import { INegotiationData, INegotiationPreparedRequest, EQuestionType, IQuestionGeneric, EAnswerType, IAnswerGeneric, templateTypeFromQuestionType, templateTypeFromAnswerType, NegotiationAnswer } from '../entities/AssetManagement/negotiations';
import { getTextFromDraft } from '../tools/draftJS';
import { EAcceptanceStatus, IUserBrief } from '../entities/IGlobal';
import { EColumnType, EOverviewColumnSubType } from '../entities/IDatagrid';
import { formDataEncodeAndAppendFile } from '../tools/files';

const resolveNegotiationClusterQuestionPath = (clusterId: string, questionId?: string) => {
    return `/clusters/${clusterId}/questions/main${questionId ? '/' : ''}${questionId || ''}`;
};

const resolveNegotiationClusterCreateSubQuestionPath = (clusterId: string, mainQuestionId?: string) => {
    return `/clusters/${clusterId}/questions/main/${mainQuestionId || ''}/sub`;
};

const resolveNegotiationClusterSubQuestionPath = (clusterId: string, questionId?: string) => {
    return `/clusters/${clusterId}/questions/sub${questionId ? '/' : ''}${questionId || ''}`;
};

const resolveNegotiationOrgQuestionPath = (organizationId: string, questionId?: string, isClauses?: boolean) => {
    if (isClauses) {
        return `/organizations/${organizationId}/clauses${questionId ? '/' : ''}${questionId || ''}`;
    } else {
        return `/organizations/${organizationId}/faq/main${questionId ? '/' : ''}${questionId || ''}`;
    }
};

const resolveNegotiationQuestionApiCall = <T>(http: Http, preparedRequest: INegotiationPreparedRequest, body?: ICreateQuestion, remove?: boolean, isClauses?: boolean): Promise<T> => {
    const { clusterId, data: question, mainQuestionId, sub } = preparedRequest;

    if (preparedRequest.clusterId) {
        let apiPath: string;

        if (sub) {
            // cluster path sub
            if (question.id) {
                apiPath = resolveNegotiationClusterSubQuestionPath(clusterId, question.id);
            } else {
                apiPath = resolveNegotiationClusterCreateSubQuestionPath(clusterId, mainQuestionId);
            }
        } else {
            // cluster path main
            apiPath = resolveNegotiationClusterQuestionPath(clusterId, question.id);
        }

        if (!preparedRequest.data.id) {
            return http.clustersApiPost(apiPath, body);
        } else if (remove) {
            return http.clustersApiDelete(apiPath);
        } else {
            return http.clustersApiPut(apiPath, body);
        }
    } else {
        // org path
        const apiPath = resolveNegotiationOrgQuestionPath(preparedRequest.organizationId, question.id, isClauses);

        if (!question.id) {
            return http.clustersApiPost(apiPath, body);
        } else if (remove) {
            return http.clustersApiDelete(apiPath);
        } else {
            return http.clustersApiPut(apiPath, body);
        }
    }
};

const resolveNegotiationClusterAnswerPath = (clusterId: string, questionId: string, answerId?: string) => {
    return `/clusters/${clusterId}/questions/${questionId}/answers${answerId ? '/' : ''}${answerId || ''}`;
};

const resolveNegotiationOrgAnswerPath = (organizationId: string, questionId: string, answerId?: string) => {
    return `/organizations/${organizationId}/faq/questions/${questionId}/answers${answerId ? '/' : ''}${answerId || ''}`;
};

const resolveNegotiationAnswerApiCall = <T>(http: Http, preparedRequest: INegotiationPreparedRequest, body?: IAnswer, remove?: boolean): Promise<T> => {
    const { organizationId, clusterId, questionId, data: answer } = preparedRequest;

    if (clusterId) {
        // cluster path
        const apiPath = resolveNegotiationClusterAnswerPath(clusterId, questionId, answer.id);

        if (!answer.id && body) {
            return http.clustersApiPost(apiPath, body);
        } else if (remove) {
            return http.clustersApiDelete(apiPath);
        } else if (body) {
            return http.clustersApiPut(apiPath, body);
        }
    } else {
        // org path
        const apiPath = resolveNegotiationOrgAnswerPath(organizationId, questionId, answer.id);

        if (!answer.id) {
            return http.clustersApiPost(apiPath, body);
        } else if (remove) {
            return http.clustersApiDelete(apiPath);
        } else {
            return http.clustersApiPut(apiPath, body);
        }
    }
};

const getRelatedItemsFromQuestion = (question: IQuestionGeneric, modifyingArticlesSelection?: boolean) => {
    const metadataArticles = [...(question.metadata?.articles || [])];
    const existingRelatedItems = [...(question.relatedItems || [])];
    const preservedRelatedItems = modifyingArticlesSelection ? existingRelatedItems.filter((item) => item.relatedItemType !== ERelatedItemType.Article) : existingRelatedItems;
    const relatedItems = question.attachments ?
        [ ...metadataArticles, ...question.attachments, ...preservedRelatedItems]
        : [...metadataArticles, ...preservedRelatedItems];

    return _.uniqBy(relatedItems, 'relatedItemIdOrCommonId');
};

export const questionDtoFromQuestionGeneric = (question: IQuestionGeneric, category?: EQuestionCategory, modifyingArticlesSelection?: boolean): ICreateQuestion => ({
    textRaw: getTextFromDraft(question.text),
    textFormatted: question.text,
    teamChannelId: question.teamChannelId,
    mainQuestionId: question.mainQuestionId,
    threadId: question.threadId,
    investorId: question.investorId,
    questionType: ETypes.New,
    questionCategory: category || EQuestionCategory.QuestionsAndAnswers,
    templateType: templateTypeFromQuestionType(question.type),
    parents: [],
    tagsIds: question.metadata?.themes?.map(elem => elem.id) || [],
    relatedItems: getRelatedItemsFromQuestion(question, modifyingArticlesSelection),
    investmentVehiclesIds: question.metadata?.vehicles?.map(elem => elem.id) || [],
    // @TODO the next line will modified be added later
    // mfnType: panelContext.mainData?.mainQuestionDto?.mfnType,
    // tslint:disable-next-line:no-null-keyword
    questionAcceptanceStatus: null
});

export const answerDtoFromAnswerGeneric = (answer: IAnswerGeneric): IAnswer => ({
    mainQuestionId: answer.mainQuestionId,
    textFormatted: answer.text,
    // tslint:disable-next-line:no-null-keyword
    textRaw: answer.text === null ? null : getTextFromDraft(answer.text),
    templateType: answer.type ? templateTypeFromAnswerType(answer.type) : undefined,
    qualification: answer.qualification,
    answerAcceptanceStatus: answer.acceptanceStatus,
    relatedItems: answer.relatedItems,
    conditionsIds: answer.conditionsIds
});

export const negotiationCommitServerRequest = <T = ISingleQuestion | IAnswerDto>(preparedRequest: any, remove?: boolean, questionCategory?: EQuestionCategory, isClauses?: boolean, modifyingArticlesSelection?: boolean) => action<Promise<T>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    if (preparedRequest.isAnswer) {
        switch (preparedRequest.data.type) {
            case EAnswerType.GENERIC:
            case EAnswerType.LPA:
            case EAnswerType.SL:
            default:
                const answerDto = answerDtoFromAnswerGeneric(preparedRequest.data as IAnswerGeneric);
                return resolveNegotiationAnswerApiCall<T>(http, preparedRequest, answerDto, remove);
        }
    } else if (preparedRequest.isQuestion) {
        switch (preparedRequest.data.type) {
            case EQuestionType.GENERIC:
            case EQuestionType.LPA:
            case EQuestionType.SL:
            default:
                let questionDto = undefined;
                if (isClauses) {
                    questionDto = {rightAnswer: preparedRequest.data.rightAnswer, rightMainQuestion: preparedRequest.data.rightMainQuestion};
                } else {
                    questionDto = questionDtoFromQuestionGeneric(preparedRequest.data as IQuestionGeneric, questionCategory, modifyingArticlesSelection);
                }
                return resolveNegotiationQuestionApiCall<T>(http, preparedRequest, questionDto, remove, isClauses);
        }
    }
});

export const negotiationPrepareServerRequests = (negotiationData: INegotiationData, organizationId?: string, clusterId?: string, questionCategory?: EQuestionCategory): INegotiationPreparedRequest[] => {
    const preparedRequests: INegotiationPreparedRequest[] = [];

    negotiationData.questionAnswers.forEach((questionAnswer) => {
        preparedRequests.push({
            organizationId,
            clusterId,
            data: questionAnswer.question,
            sub: questionAnswer.index > 0,
            mainQuestionId: questionAnswer.question.mainQuestionId,
            isQuestion: true
        });

        if (questionAnswer.answer) {
            preparedRequests.push({
                organizationId,
                clusterId,
                data: questionAnswer.answer,
                sub: questionAnswer.index > 0,
                isAnswer: true,
                mainQuestionId: questionAnswer.answer.mainQuestionId,
                questionId: questionAnswer.question.id
            });
        }
    });

    return preparedRequests;
};

export interface IRightAnswer {
    mainQuestionId?: string;
    answerAcceptanceStatus?: EAcceptanceStatus;
    conditionsIds?: string[];
    templateType?: ETemplateType;
    textFormatted: string;
    textRaw: string;
}

export interface IRightQuestion {
    questionAcceptanceStatus?: string;
    relatedItems?: IRelatedItem[];
    templateType?: ETemplateType;
    textFormatted: string;
    textRaw: string;
}

export interface ICreateRightVersion {
    rightAnswer: IRightAnswer;
    rightQuestion: IRightQuestion;
}

export interface ICreateRightVersionResponse {
    rightId: string;
    rightAnswer: IAnswerDto;
    rightQuestion: IQuestionDto;
}

interface AMDatagrid {
    businessId: number;
    category: {
        enumElement: string;
        translationKey: string;
        value: string;
    };
    status: {
        enumElement: string;
        translationKey: string;
        value: string;
    };
    comment: number;
    thread: number;
    conditions: [];
    hasAlternatives: boolean;
    id: string;
    lastAnswer: string;
    lastEditedBy: IUserBrief[];
    lastUpdatedOrCreationDate: string;
    query: string;
    relatedArticles: [];
    relatedFaq: [];
    relatedProvisions: [];
    relatedThemes: [];
    relatedThreads: [];
}

export const createClusterProvisionVersion = (clusterId: string, mainQuestionId: string, body: ICreateRightVersion) => action<Promise<ICreateRightVersionResponse>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/rights/${mainQuestionId}/versions`, body);
});

export const updateClusterProvisionVersion = (clusterId: string, mainQuestionId: string, questionId: string, body: ICreateRightVersion) => action<Promise<ICreateRightVersionResponse>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/rights/${mainQuestionId}/versions/${questionId}`, body);
});

export const createOrgClauses = (organizationId: string, body: ICreateRightVersion) => action<Promise<any>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clauses`, body);
});

export const updateOrgClauses = (organizationId: string, mainQuestionId: string, body: IUpdateRight) => action<Promise<any>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${organizationId}/clauses/${mainQuestionId}`, body);
});

export const createOrgProvisionVersion = (orgId: string, mainQuestionId: string, body: ICreateRightVersion) => action<Promise<ICreateRightVersionResponse>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/rights/${mainQuestionId}/versions`, body);
});

export const updateOrgProvisionVersion = (orgId: string, mainQuestionId: string, questionId: string, body: ICreateRightVersion) => action<Promise<ICreateRightVersionResponse>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${orgId}/rights/${mainQuestionId}/versions/${questionId}`, body);
});

export const createRight = (clusterId: string, body: ICreateRight) => action<Promise<ICreateRightResponse>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/rights`, body);
});

export const updateRight = (clusterId: string, rightId: string, body: IUpdateRight) => action<Promise<ICreateRightResponse>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/rights/${rightId}`, body);
});

export const getMiniDatatable = (clusterId: string, mainQuestionId: string) => action<Promise<IRightsMiniDatatableEntry[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/clusters/${clusterId}/questions/main/${mainQuestionId}/related/rights/mini-datatable`);
});

export const searchInFaq = (orgId: string, body: ISearchFaq) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/faq/search`, body);
});

export const searchInClauses = (orgId: string, body: ISearchFaq) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/Clauses/search`, body);
});

export const useFromFaq = (clusterId: string, questionId: string, faqElementId: string) => action<Promise<void>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/questions/${questionId}/answers/from-faq/${faqElementId}`);
});

export const createClusterQuestion = (clusterId: string, body: ICreateQuestion) => action<Promise<ISingleQuestion>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/questions/main`, body);
});

export const createSideLetterMain = (clusterId: string, body: ICreateQuestion) => action<Promise<ISingleQuestion>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return createClusterQuestion(clusterId, body);
});

export const createMainFAQ = (orgId: string, body: ICreateQuestion) => action<Promise<ISingleQuestion>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/faq/main`, body);
});

export const updateClusterQuestion = (clusterId: string, questionId: string, body: ICreateQuestion) => action<Promise<ISingleQuestion>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/questions/main/${questionId}`, body);
});

export const deleteClusterQuestion = (clusterId: string, questionId: string) => action<Promise<ISingleQuestion>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/questions/main/${questionId}`);
});

export const addQuestionAnswerToFaq = (clusterId: string, mainQuestionId: string, answerId: string) => action<Promise<void>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/questions/main/${mainQuestionId}/answers/${answerId}/add-to-faq`);
});

export const addQuestionToFaq = (clusterId: string, questionId: string) => action<Promise<ISingleQuestion>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/questions/main/${questionId}/add-to-faq`);
});

export const updateFAQ = (orgId: string, questionId: string, body: ICreateQuestion) => action<Promise<ISingleQuestion>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${orgId}/faq/main/${questionId}`, body);
});

export const createClusterSubQuestion = (clusterId: string, mainQuestionId: string, body: { textFormatted: string, textRaw: string, teamChannelId: string }) => action<Promise<IQuestionDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/questions/main/${mainQuestionId}/sub`, body);
});

export const getMainRight = (clusterId: string, rightId: string) => action<Promise<IGetMainQuestion<IQuestionDto, IAnswerDto>>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/clusters/${clusterId}/questions/main/${rightId}`);
});

export const getMainQuestion = (clusterId: string, mainQuestionId: string) => action<Promise<IGetMainQuestion<IQuestionDto, IAnswerDto>>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/clusters/${clusterId}/Questions/main/${mainQuestionId}`);
});

export const getMainFaq = (orgId: string, mainQuestionId: string) => action<Promise<IGetMainQuestion<IQuestionDto, IAnswerDto>>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/organizations/${orgId}/faq/main/${mainQuestionId}`);
});

export const getMainClausier = (orgId: string, mainQuestionId: string) => action<Promise<IGetMainQuestion<IQueryDtoClausier, IAnswerDtoClausier>>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/organizations/${orgId}/faq/main/${mainQuestionId}`);
});

export const updateMainClausier = (orgId: string, clauseId: string, body: ICreateQueryClausier) => action<Promise<IQueryDtoClausier>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${orgId}/clauses/main/${clauseId}`, body);
});

export const createClausierRight = (orgId: string, mainQuestionId: string, body: IAnswer) => action<Promise<IAnswerDtoClausier>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/faq/questions/${mainQuestionId}/answers`, body);
});

export const addRightToClauses = (clusterId: string, queryId: string) => action<Promise<ISingleQueryClausier>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/queries/main/${queryId}/add-to-clauses`);
});

export const addSubRightToClauses = (clusterId: string, queryId: string, subQueryId: string) => action<Promise<ISingleQueryClausier>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/clauses/queries/main/${queryId}/add-to-clauses/sub/${subQueryId}`);
});

export const updateClausierRight = (orgId: string, queryId: string, rightId: string, body: IAnswer) => action<Promise<IAnswerDtoClausier>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${orgId}/clauses/queries/${queryId}/rights/${rightId}`, body);
});

export const deleteClausierRight = (orgId: string, mainQuestionId: string) => action<Promise<void>>(async (dispatch: Dispatch, getStates: () => IState, http: Http) => {
    return http.clustersApiDelete(`/organizations/${orgId}/faq/main/${mainQuestionId}/`);
});

const defaultExploreSettings = { columnsSettings: [
    {
        translationKey: 'customDatagrid.cluster.templateType',
        field: 'cluster-template-type',
        headerText: 'Type',
        isVisible: true,
        type: EColumnType.clusterTemplate,
        width: 72
    },
    {
        translationKey: 'customDatagrid.cluster.clusterOverview',
        field: 'cluster-overview',
        headerText: 'Overview',
        isVisible: true,
        subType: EOverviewColumnSubType.cluster,
        type: EColumnType.overview
    },
    {
        subType: 'messages',
        translationKey: 'customDatagrid.cluster.numberOfMessages',
        field: 'number-of-messages',
        headerText: 'Activity',
        isVisible: true,
        type: EColumnType.hyperlink,
        width: 104
    },
    {
        subType: 'documents',
        translationKey: 'customDatagrid.cluster.numberOfDocuments',
        field: 'number-of-documents',
        headerText: 'Document',
        isVisible: true,
        type: EColumnType.hyperlink,
        width: 104
    },
    {
        translationKey: 'customDatagrid.cluster.clusterPeople',
        field: 'cluster-people',
        headerText: 'People',
        isVisible: true,
        type: EColumnType.people,
        width: 104
    },
    {
        subType: 'relativeDate',
        translationKey: 'customDatagrid.cluster.lastEditedBy',
        field: 'last-edited-by',
        headerText: 'Last edited by',
        isVisible: true,
        type: EColumnType.lastEditedBy,
        width: 200
    }
]};

export const getSettingsClusterDatagrid = (organizationId: string, key: string) => action<Promise<ITemplateDataGridSettings>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    if (key === 'clusters-explore' && !organizationId)
        return defaultExploreSettings;
    return http.clustersApiGet(`/organizations/${organizationId}/custom-datagrid-by-key/${key}/settings?lcid=${language.userLanguage || ELcid.En}`);
});

export const getSettingsFaQDatagrid = (organizationId: string) => action<Promise<ITemplateDataGridSettings>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organizationId}/custom-datagrid-by-key/custom-frequently-asked-questions-organization/settings?lcid=${language.userLanguage || ELcid.En}`);
});

export const getSettingsClausierDatagrid = (organizationId: string) => action<Promise<ITemplateDataGridSettings>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organizationId}/custom-datagrid-by-key/custom-clausier-organization/settings?lcid=${language.userLanguage || ELcid.En}`);
});

export const getSettingsAllRightsDatagrid = (organizationId: string) => action<Promise<ITemplateDataGridSettings>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organizationId}/custom-datagrid-by-key/custom-rights-organization/settings?lcid=${language.userLanguage || ELcid.En}`);
});

export const uploadAssetManagementFiles = (organizationId: string, clusterId: string, assetMangementBaseType: EAssetManagementBaseType, assetManagementItemId: string, files: File[]) => action<Promise<any>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const formData = new FormData();

    for (let file of files) {
        formDataEncodeAndAppendFile(formData, 'files', file);
    }

    if (clusterId) {
        return http.clustersApiUpload(`/organizations/${organizationId}/clusters/${clusterId}/${assetMangementBaseType}/${assetManagementItemId}/attachments`, formData, () => { /** */ });
    }

    return http.clustersApiUpload(`/organizations/${organizationId}/${assetMangementBaseType}/${assetManagementItemId}/attachments`, formData, () => { /** */ });
});

export const deleteAssetManagementFile = (organizationId: string, clusterId: string, assetManagementBaseType: EAssetManagementBaseType, assetManagementItemId: string, fileId: string, body: { name: string, path: string }) => action<Promise<any[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    if (clusterId) {
        return http.clustersApiDelete(`/organizations/${organizationId}/clusters/${clusterId}/${assetManagementBaseType}/${assetManagementItemId}/attachments/${fileId}`, body);
    }

    return http.clustersApiDelete(`/organizations/${organizationId}/${assetManagementBaseType}/${assetManagementItemId}/attachments/${fileId}`, body);
});

type FetcherFn<T> = (orgId: string, filters: object) => Promise<T>;

const cachedByOrgsFilters = <T>(fetcherFn: FetcherFn<T>): FetcherFn<T> => {
    let cache = {};

    return (orgId: string, filters: object) => {
        if (cache[orgId]?.has(filters)) {
            return cache[orgId].get(filters);
        }
        const result = fetcherFn(orgId, filters);
        cache = { [orgId]: new Map([[ filters, result ]]) };
        return result;
    };
};

export const getDataRightDatagrid = cachedByOrgsFilters((organizationId: string, filters) => action<Promise<IDataTableData[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/Rights/consolidated/datagrid`, filters);
}));

export const getSideLetterDatagrid = cachedByOrgsFilters((clusterId: string, filters: any) => action<Promise<AMDatagrid[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    // tslint:disable-next-line: no-null-keyword
    return http.clustersApiPost(`/clusters/${clusterId}/rights/datagrid`, { ...filters, hasFollowUpQuestions: filters.hasFollowUpQuestions?.[0] ? filters.hasFollowUpQuestions?.[0] === 'true' : null });
}));

export const getDataOrgrDatagrid = (orgId: string, filters, cancelCall) => action<Promise<IDataTableData[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/Questions/datagrid`, filters, cancelCall);
});

export const getDataClusterDatagrid = (clusterId: string, filters, cancelCall) => action<Promise<IDataTableData[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/Questions/datagrid`, filters, cancelCall);
});

export const getDataFaqDatagrid = cachedByOrgsFilters((orgId: string, filters) => action<Promise<AMDatagrid[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return  http.clustersApiPost(`/organizations/${orgId}/faq/datagrid`, filters);
}));

export const getDataClausierDatagrid = cachedByOrgsFilters((orgId: string, filters) => action<Promise<AMDatagrid[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/clauses/datagrid`, filters);
}));

export const getDataAllRightsDatagrid = cachedByOrgsFilters((orgId: string, filters) => action<Promise<AMDatagrid[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/rights/datagrid`, filters);
}));

export const setAnswerToQuestion = (clusterId: string, questionId: string, body: IAnswer, type: 'questions' | 'rights') => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/${type}/${questionId}/answers`, body);
});

export const setVersionToSubQuery = (clusterId: string, questionId: string, body: IAnswer) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/queries/${questionId}/rights`, body);
});

export const setRightToQuery = (clusterId: string, questionId: string, body: IAnswer) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/queries/${questionId}/rights`, body);
});

export const setFaqAnswerToQuestion = (orgId: string, questionId: string, body: IAnswer) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/faq/questions/${questionId}/answers`, body);
});

export const updateAnswerToQuestion = (clusterId: string, questionId: string, answerId: string, body: IAnswer, type: 'questions' | 'queries') => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/${type}/${questionId}/answers/${answerId}`, body);
});

export const updateVersionToSubQuery = (clusterId: string, questionId: string, answerId: string, body: IAnswer) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/queries/${questionId}/rights/${answerId}`, body);
});

export const updateRightToQuery = (clusterId: string, questionId: string, answerId: string, body: IAnswer) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/queries/${questionId}/rights/${answerId}`, body);
});

export const deleteSubQuestionThread = (clusterId: string, questionId: string) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/questions/sub/${questionId}`);
});

export const deleteSubAnswerThread = (clusterId: string, questionId: string, answerId: string) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/questions/${questionId}/answers/${answerId}`);
});

export const deleteThread = (clusterId: string, questionId: string, answerId: string) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/queries/${questionId}/rights/${answerId}`);
});

export const deleteSubQuery = (clusterId: string, rightId: string) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/queries/sub/${rightId}`);
});

export const updateSubQuestion = (clusterId: string, questionId: string, body: { textFormatted: string, textRaw: string }) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/questions/sub/${questionId}`, body);
});

export const updateAnswerToFaq = (orgId: string, questionId: string, answerId: string, body: IAnswer) => action<Promise<IAnswerDto>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/organizations/${orgId}/clauses/questions/${questionId}/answers/${answerId}`, body);
});

export const getFaqForQuestion = (orgId: string, body: ISearchFaqToQnA) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/Faq/search`, body);
});

export const getQueriesForQuestion = (orgId: string, body: ISearchFaqToQnA) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${orgId}/clauses/search`, body);
});

export const pickFaqInQnA = (clusterId: string, questionId: string, faqElementId: string) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/questions/${questionId}/answers/from-faq/${faqElementId}`);
});

export const pickClausesInRight = (clusterId: string, questionId: string, faqElementId: string) => action<Promise<any>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/queries/${questionId}/rights/from-clausier/${faqElementId}`);
});

export const getConditions = () => action<Promise<ICondition[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/conditions/search`, { conditionTypeFilter: [0] });
});

export const createCondition = (condition: ICondition) => action<Promise<ICondition>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/conditions`, condition);
});

export const getCommonHeaders = (context: IContextReducer, config: IConfigReducer) => ({
    'Authorization': `Bearer ${context.token}`,
    'Ocp-Apim-Subscription-Key': config.subKey,
    'Content-Type': 'application/json'
});

export const parseFileNameAndBlobFromRes = async (res: Response): Promise<[string | undefined, Blob]> => {
    const blob = await res.blob();
    const fileNameToSplit = res.headers.get('content-disposition')?.split('filename=')[1]?.split(';')?.[0];

    if (fileNameToSplit?.[0] === '"') {
        return [fileNameToSplit.split('"')[1], blob];
    }

    return [undefined, blob];
};

export const exportToExcel = (clusterId: string, filters, exportType: ExportType, exportContext: string) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { context, config } = getState();
    return fetch(`${config.clustersApiUrl}/clusters/${clusterId}/${exportContext}/export/xslt`, {
        method: 'POST',
        body: JSON.stringify({ ...filters, exportType, direction: EDirectionType.Ascending, orderBy: EOrderByType.CreatedDate }),
        headers: getCommonHeaders(context, config)
    }).then(parseFileNameAndBlobFromRes).then(([fileName, blob]) => {
        blob && saveAs(blob, fileName || 'Export.xlsx');
    });
});

export const exportOrgToExcel = (orgId: string, filters, exportType: ExportType, exportContext: string) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { context, config } = getState();
    return fetch(`${config.clustersApiUrl}/organizations/${orgId}/${exportContext}/export/xslt`, {
        method: 'POST',
        body: JSON.stringify({ ...filters, direction: EDirectionType.Ascending, orderBy: EOrderByType.CreatedDate, exportType, organizationId: orgId, questionCategoryFilter: exportContext, onlyQuestionsWithAnswers: undefined }),
        headers: getCommonHeaders(context, config)
    }).then(parseFileNameAndBlobFromRes).then(([fileName, blob]) => {
        blob && saveAs(blob, fileName || 'Export.xlsx');
    });
});

export const exportSideLetterToWordOrPdf = (clusterId: string, includeAttachments: boolean, investmentVehicleId?: string) => action<Promise<IGetFaqForQnA[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { context, config } = getState();
    const params = {
        includeAttachments: includeAttachments ? 'true' : 'false',
        investmentVehicleId
    };

    const searchParams = new URLSearchParams(Object.entries(params).filter(([key, val]) => val !== undefined));

    return fetch(`${config.clustersApiUrl}/clusters/${clusterId}/side-letter/generate?${searchParams.toString()}`, {
        method: 'POST',
        headers: getCommonHeaders(context, config)
    });
});

export const getHasSideLetterAttachments = (clusterId: string, investmentVehicleId?: string) => action<Promise<boolean>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const params = {
        investmentVehicleId
    };

    const searchParams = new URLSearchParams(Object.entries(params).filter(([key, val]) => val !== undefined));

    return http.clustersApiGet(`/clusters/${clusterId}/side-letter/attachments-exists?${searchParams.toString()}`);
});
